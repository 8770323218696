<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="1000px">
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
            style="padding-right: 10px !important"
          >
            <v-toolbar-title class="overline">{{
              titleParticipantModal
            }}</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="closeDialog">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-if="!disabledEdit" class="mt-4">
                  <v-autocomplete
                    outlined
                    :items="members"
                    item-value="id"
                    item-text="person.corporateName"
                    v-model="payload.personId"
                    label="Membro"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" :class="disabledEdit ? 'mt-4' : 'mt-n8'">
                  <v-text-field
                    outlined
                    v-model="payload.office"
                    label="Cargo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="mr-n8">
                  <v-switch
                    v-model="payload.active"
                    :label="payload.active ? 'Lider? SIM' : 'Lider? NÃO'"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="registerOrUpdateParticipant()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="searchParticipants"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Membro</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headerParticipants"
          :items="membersGroup"
          :search="searchParticipants"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              small
              v-model="item.active"
              disabled
              :label="item.active ? 'Sim' : 'Não'"
            ></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="editParticipant(item)">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "MinistryPerson",
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Membros Ministério",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Ministérios",
        disabled: false,
        href: "/promise/ministry",
      },
      {
        text: "Membros",
        disabled: true,
        href: "/members",
      },
    ],
    deleteDialog: false,
    search: "",
    searchParticipants: "",
    members: [],
    loading: false,
    loadingAction: false,
    titleParticipantModal: "Novo Participante",
    membersGroup: [],
    ministry: {
      id: null,
    },
    payload: {
      personId: null,
      office: "",
      active: false,
    },
    ministryId: null,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    openDialog: false,
    participantModal: false,
    disabledEdit: false,
    headerParticipants: [
      {
        text: "Nome",
        align: "start",
        value: "person.person.corporateName",
        filtering: true,
      },
      {
        text: "Cargo",
        align: "start",
        value: "office",
        filtering: true,
      },
      {
        text: "WhatsApp",
        align: "start",
        value: "person.contact.whatsApp",
        filtering: true,
      },
      {
        text: "Lider?",
        align: "start",
        value: "active",
      },
      { text: "Ações", value: "actions" },
    ],
    offices: [
      "Líder",
      "Vocalista",
      "Tecladista",
      "Violonista",
      "Baterista",
      "Corista",
      "Roteirista",
      "Maestro",
    ],
  }),
  methods: {
    registerOrUpdateParticipant() {
      if (this.id) {
        http
          .patch(
            `promise/ministry/${this.ministryId}/persons/${this.id}`,
            this.payload
          )
          .then(
            (a) => {
              this.snackbar.text = "Membro atualiado com sucesso";
              this.snackbar.color = "green";
              this.snackbar.opened = true;
              this.closeDialog();
            },
            () => {
              this.snackbar.text = "Erro ao atualizar participante";
              this.snackbar.color = "red";
              this.snackbar.opened = true;
            }
          );
      } else {
        http
          .post(`promise/ministry/${this.ministryId}/persons`, this.payload)
          .then(
            (a) => {
              this.snackbar.text = "Membro adicionado com sucesso";
              this.snackbar.color = "green";
              this.snackbar.opened = true;
              this.closeDialog();
            },
            () => {
              this.snackbar.text = "Erro ao adicionar participante";
              this.snackbar.color = "red";
              this.snackbar.opened = true;
            }
          );
      }
    },
    async getParticipants() {
      this.loading = true;
      http.get(`promise/ministry/${this.ministryId}/persons`).then(
        (res) => {
          const { data } = res;
          this.membersGroup = data;
          this.loading = false;
          let personIds = this.membersGroup.map((a) => a.personId);
          personIds.push("-1");
          this.getAllMembers(personIds);
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao buscar Membros";
          this.snackbar.opened = false;
          this.loading = false;
        }
      );
    },
    closeDialog() {
      this.id = null;
      this.payload.personId = null;
      this.payload.office = "";
      this.payload.active = false;
      this.disabledEdit = false;
      this.openDialog = false;
      this.getParticipants();
    },
    openModalType(ministry) {
      if (!ministry && this.ministry.id) ministry = this.ministry;
      this.openDialog = true;
    },
    editParticipant(participant) {
      this.disabledEdit = true;
      this.id = participant.id;
      this.payload = participant;
      this.titleParticipantModal =
        "Atualizar Membro " + participant.person.person.corporateName;
      this.openDialog = true;
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions
          ?.map((a) => a.id)
          .includes("promise.ministry_persons.delete_one")
      ) {
        return true;
      }
      return false;
    },
    getAllMembers(personIds) {
      http
        .get(`gerentor/customers?filter=id||$notin||${personIds}`)
        .then((res) => {
          this.members = res.data;
        });
    },
    deleteItem() {
      http
        .delete(`promise/ministry/${this.ministryId}/persons/${this.id}`)
        .then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Excluido com Sucesso!";
            this.snackbar.opened = true;
            this.id = null;
            this.deleteDialog = false;
            this.getParticipants(this.ministry);
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
            this.snackbar.opened = true;
          }
        );
    },
    async getItems() {
      this.loading = true;
      const ministryId = this.$route.params.ministryId;
      if (!ministryId) {
        this.snackbar.color = "red";
        this.snackbar.text = "Falha ao Localizar Grupo";
        this.snackbar.opened = true;
        this.loading = false;
        return;
      }
      this.ministryId = ministryId;
      http.get(`promise/cells/${ministryId}`).then(
        async (data) => {
          this.ministry = data.data;
          this.title = `Membros ${this.ministry.name}`;
          await this.getParticipants();
          this.loading = false;
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao Localizar Grupo";
          this.snackbar.opened = true;
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>
